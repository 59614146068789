
import { defineComponent, ref, onMounted } from "vue"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"
import type { ElForm } from "element-plus"
import type { ElUpload, ElInput } from "element-plus"
import { Member } from "@/store/modules/MemberModule"

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  components: {
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("用户编辑", ["用户管理;members", route.params.id.toString()])
    })

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    });

    const formRef = ref<FormInstance>()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const member = ref({} as Member)

    const onCancel = () => {
      router.push({ name: "members" });
    };

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return

      formEl.validate((valid) => {
        if (valid) {
          store
            .dispatch(Actions.UPDATE_MEMBER, {
              id: route.params.id,
              values: member.value,
            })
            .then(() => {
              router.push({ name: "members" });
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        }
      })
    }

    store
      .dispatch(Actions.GET_MEMBER, route.params.id)
      .then(() => {
        member.value = store.getters.currentMember
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    return {
      onSubmit,
      member,
      rules,
      onCancel,
      formRef
    }
  }
});
